* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.content {
  background: rgb(255, 255, 255);
  padding:10px 0px ;
  max-width: 100vw;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: #ffffff;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 350px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}
.logos-slide  {
  display: inline-block;
  gap: 50px;
  animation: 35s slide infinite linear;
 
}

.logos-slide img {
  height: 50px;
  margin: 0px 50px 0px 50px;
}

@media (max-width: 600px) {
  .logos:before,
  .logos:after {
    width: 100px; /* Adjust as needed for smaller screens */
  }
.logos-slide img {
  margin: 0px 10px 0px 10px;
}
}
.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


::-webkit-scrollbar {
  width: 8px; /* Ancho del scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: #78997d; /* Color del scrollbar */
  border-radius: 10px; /* Bordes redondeados */
}

::-webkit-scrollbar-track {
  background: #f1f1f100; /* Fondo de la pista del scrollbar */
}

/* Para Firefox */
* {
  scrollbar-width: thin; /* Ancho del scrollbar */
  scrollbar-color: #78997d #f1f1f1; /* Color del thumb y del track */
}
.field {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  /* border: 1px solid red; */
  
  /* height: 200px; */
}

.label {
  /* align-self: flex-start; */
  padding: 9px;
  border: none;
  margin: 5px;
  background-color: rgba(217, 217, 217, 1);
  border-radius: 10px;
  width: 100%;
  height: 30px;
  font-size: 16px;
  font-family: 'K2D';
  font-style: normal;
  @media screen and (max-width: 768px) {
    font-size: 10px;
    /* width: 90%; */
    height: 25px;
  }
}

.input {
 
  /* align-self: flex-start; */
  background-color: rgba(217, 217, 217, 1);
  padding: 10px;
  border: none;
  border-radius: 10px;
  width: 100%;
  height: 135px;
  /* min-height: 100px; */
  margin-top: 15px;
  font-size: 16px;
  font-family: 'K2D';
  font-style: normal;
  @media screen and (max-width: 768px) {
    font-size: 10px;
    /* width: 90%; */
    height: 50px;
    margin-top: 8px;
  }
}
.send{
  align-self: flex-start;
  width: 95%;

}
input[type='submit'] {
  width: 100px;
  height: 33px;
  margin: 5px;
  border-radius: 10px;
  background: #446844;
  color: #ffffff;
  /* margin: 1rem; */
  
  align-self: center;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}
input[type='submit']:hover {
  background: #3a563a;
}
input[type='submit']:active {
  transform: translateY(2px); /* Desplazar ligeramente hacia abajo */
}

.styled-button {
  
  background-color: #3a563a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.styled-button:hover {
  background-color: #588358;
}


.recaptcha-container {
  /* border: 1px solid red; */
  width: auto; /* Haz que ocupe todo el ancho disponible */
  display: flex;  
  justify-content: center;
  @media (max-width: 480px){
    width: 50%;
  } /* Centra el reCAPTCHA */
}

.recaptcha-container > div {
  transform: scale(0.9); /* Escala inicial */
  transform-origin: center center; /* Ajusta el punto de escala */
}

@media (max-width: 768px) {
  .recaptcha-container > div {
    transform: scale(0.65); /* Reduce el tamaño en pantallas medianas */
  }
}

@media (max-width: 480px) {
  .recaptcha-container > div {
    transform: scale(0.6); /* Reduce aún más en pantallas pequeñas */
  }
}




.styled-button {
  background-color: #3a563a;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.styled-button:hover {
  background-color: #588358;
}

.cancel-button {
  background-color: #ccc;
  color: #333;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cancel-button:hover {
  background-color: #aaa;
}

@keyframes slideInFromLeft {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-slideInFromLeft {
  animation: slideInFromLeft 1s ease-out forwards;
}